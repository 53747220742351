export default [
  {
    header: 'side_bar.users_label',
  },
  {
    title: 'side_bar.message',
    route: 'message',
    icon: 'CheckSquareIcon',
    class: 'not_ready'
  },
  {
    title: 'side_bar.user',
    route: 'user',
    icon: 'CheckSquareIcon',
  },
]
