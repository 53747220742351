export default [
  {
    header: 'side_bar.shops_label',
  },
  {
    title: 'side_bar.company',
    route: 'company',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'side_bar.contacts',
    route: 'contact',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'side_bar.supplier',
    route: 'supplier',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'side_bar.shop',
    route: 'shop',
    icon: 'CheckSquareIcon',
  },
]
