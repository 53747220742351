export default [
  {
    header: 'side_bar.products_label',
  },
  {
    title: 'side_bar.specification',
    route: 'specification',
    icon: 'CalendarIcon',
  },
  {
    title: 'side_bar.brand',
    route: 'brand',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'side_bar.category',
    route: 'category',
    icon: 'MailIcon',
  },
  {
    title: 'side_bar.product',
    route: 'product',
    icon: 'CheckSquareIcon',
  },
  // {
  //   title: 'side_bar.variation',
  //   route: 'variation',
  //   icon: 'FileTextIcon',
  //   class: 'not_ready'
  // },
]
