export default [
  {
    header: 'side_bar.regions_label',
  },
  {
    title: 'side_bar.country',
    route: 'country',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'side_bar.region',
    route: 'region',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'side_bar.city',
    route: 'city',
    icon: 'CheckSquareIcon',
  },
]
