export default [
  {
    header: 'side_bar.tasks_label',
  },
  {
    title: 'side_bar.task',
    route: 'task',
    icon: 'CheckSquareIcon',
  },
]
