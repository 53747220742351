export default [
  {
    header: 'side_bar.home',
  },
  {
    title: 'side_bar.home_label',
    route: 'home',
    icon: 'CheckSquareIcon',
  },
]
