/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import home from './home'
import products from './products'
import regions from './regions'
import shops from './shops'
import users from './users'
import tasks from './tasks'
import reports from './reports'

// Array of sections
export default [
  ...home,
  ...products,
  ...regions,
  ...shops,
  ...users,
  ...tasks,
  ...reports,

]
