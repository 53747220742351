export default [
  {
    header: 'side_bar.reports_label',
  },
  {
    title: 'side_bar.reports',
    route: 'report',
    icon: 'CheckSquareIcon',
  },
]
